@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-Light-Pro.otf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-LightItalic-Pro.otf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-Book-Pro.otf');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-BookItalic-Pro.otf');
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-Medium-Pro.otf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-MediumItalic-Pro.otf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-Semibold-Pro.otf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-SemiboldItalic-Pro.otf');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-Bold-Pro.otf');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'IdealSans';
  src: url('./assets/fonts/IdealSans-BoldItalic-Pro.otf');
  font-style: italic;
  font-weight: 700;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IdealSans';
}

div {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 20px;
}
::-webkit-scrollbar-track {
  background-color: #e8e8e8;
}
::-webkit-scrollbar-thumb {
  background-color: lightgray;
  opacity: 0.1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.option-image {
  transform: scale(1.1) !important;
  transition: transform 0.25s ease-in-out !important;
}

.people-option:hover .option-image,
div[class*='-singleValue'] .option-image {
  transform: scale(1.25) !important;
}

.container {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
}
