.container:hover .name {
  transform: scale(1.05);
}
.container .name {
  transform: scale(1);
  transform-origin: bottom center;
  transition: all 0.5s ease;
  will-change: transform;
}
.container:hover .profile-image {
  transform: scale(1.05);
}
.container .profile-image {
  transform: scale(1);
  transform-origin: bottom center;
  transition: all 0.5s ease;
  will-change: transform;
}
